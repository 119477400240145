import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const detectionStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 정보가져오기
        GetList: ({commit}, params) => { 

            var token = VueCookies.get('reoilcms_accessToken');
            var header = "Bearer " + token;
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Detection/GetList', params,
                { headers: { Authorization : header}, }
                ).then(res => {
                    //console.log(res.data);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 엑셀다운로드
        exportExcel: ({commit}, params) => { 

            window.location = SERVER.URL + '/api/Detection/ExportExcel?schtype='+params.SchType+'&schtext='+params.SchText+'&startdate='+params.StartDate+'&enddate='+params.EndDate;

        },
        
    }
}

export default detectionStore
