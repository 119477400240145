import { createStore } from 'vuex';
import i18n from '../i18n';
import axios from 'axios'
import VueCookies from 'vue-cookies';

import adminStore from '@/store/modules/adminStore.js';
import deviceStore from '@/store/modules/deviceStore.js';
import exchangeStore from '@/store/modules/exchangeStore.js';
import faqStore from '@/store/modules/faqStore.js';
import heartbeatStore from '@/store/modules/heartbeatStore.js';
import loginStore from '@/store/modules/loginStore.js';
import noticeStore from '@/store/modules/noticeStore.js';
import pointStore from '@/store/modules/pointStore.js';
import priceStore from '@/store/modules/priceStore.js';
import settingStore from '@/store/modules/settingStore.js';
import userStore from '@/store/modules/userStore.js';
import detectionStore from '@/store/modules/detectionStore.js';


export default new createStore({
    state: {
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        locale: null,
        menu_style: 'vertical',
        layout_style: 'full',
        countryList: [
            { code: 'zh', name: 'Chinese' },
            { code: 'da', name: 'Danish' },
            { code: 'en', name: 'English' },
            { code: 'fr', name: 'French' },
            { code: 'de', name: 'German' },
            { code: 'el', name: 'Greek' },
            { code: 'hu', name: 'Hungarian' },
            { code: 'it', name: 'Italian' },
            { code: 'ja', name: 'Japanese' },
            { code: 'pl', name: 'Polish' },
            { code: 'pt', name: 'Portuguese' },
            { code: 'ru', name: 'Russian' },
            { code: 'es', name: 'Spanish' },
            { code: 'sv', name: 'Swedish' },
            { code: 'tr', name: 'Turkish' },
        ],
        user:{
            loggedIn: false,
            data: null
        }
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.global.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },

        setLoggedIn(state, value){
            state.user.loggedIn = value;
        },

        setUser(state, data){
            state.user.data = data;
            VueCookies.set('reoilcms_accessToken', data.accessToken);
            VueCookies.set('reoilcms_refreshToken', data.refreshToken);
            VueCookies.set('reoilcms_userUqId', data.userUqId);
            VueCookies.set('reoilcms_userId', data.userId);
        },

        logoutUser(state){
            state.user.loggedIn = false;
            state.user.data = null;
            VueCookies.remove('reoilcms_accessToken');
            VueCookies.remove('reoilcms_refreshToken');
            VueCookies.remove('reoilcms_userUqId');
            VueCookies.remove('reoilcms_userUqId');
        },

        resetUserInfo(state, data){
            if(state.user.data == null){
                state.user.data = data;
                VueCookies.set('reoilcms_accessToken', data.accessToken);
                VueCookies.set('reoilcms_refreshToken', data.refreshToken);
                VueCookies.set('reoilcms_userUqId', data.userUqId);
                VueCookies.set('reoilcms_userId', data.userId);
            }
        },

    },
    getters: {
        layout(state) {
            return state.layout;
        },
        user(state){
            return state.user;
        },
    },
    actions: {},
    modules: {
        adminStore: adminStore,
        deviceStore: deviceStore,
        exchangeStore: exchangeStore,
        faqStore: faqStore,
        heartbeatStore: heartbeatStore,
        loginStore: loginStore,
        noticeStore: noticeStore,
        pointStore: pointStore,
        priceStore: priceStore,
        settingStore: settingStore,
        userStore: userStore,
        detectionStore: detectionStore,
    },
});
