import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/index.vue';
import store from '../store';
import VueJwtDecode from 'vue-jwt-decode';
import VueCookies from 'vue-cookies';

// Vue.use(VueRouter);
// import { createApp } from 'vue';
// const app = createApp();
// app.use(createRouter);

const routes = [
    //dashboard
    { 
        path: '/', 
        name: 'Home', 
        meta: {
            authRequired: true,
        },
        component: Home
    },

    {
        path: '/index2',
        name: 'index2',
        component: () => import(/* webpackChunkName: "index2" */ '../views/index2.vue'),
    },
    // 로그인
    {
        path: '/auth/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth-login" */ '../views/auth/login.vue'),
        meta: { layout: 'auth' },
    },

    //고객관리
    {
        path: '/', 
        name: 'Home',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/customer/list.vue'),
        meta: {
            authRequired: true,
        },
    },
    //고객관리 수정
    {
        path: '/customer/detail', 
        name: 'customerdetail',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/customer/detail.vue'),
        meta: {
            authRequired: true,
        },
    },
    //장비관리
    {
        path: '/device/list', 
        name: 'devicelist',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/device/list.vue'),
        meta: {
            authRequired: true,
        },
    },
    //장비관리 수정
    {
        path: '/device/detail', 
        name: 'devicedetail',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/device/detail.vue'),
        meta: {
            authRequired: true,
        },
    },
    //장비로그인 기록
    {
        path: '/heartbeat/history', 
        name: 'heartbeathistory',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/heartbeat/history.vue'),
        meta: {
            authRequired: true,
        },
    },
    //가격관리
    {
        path: '/price/list', 
        name: 'pricelist',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/price/list.vue'),
        meta: {
            authRequired: true,
        },
    },
    //가격관리 수정
    {
        path: '/price/detail', 
        name: 'pricedetail',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/price/detail.vue'),
        meta: {
            authRequired: true,
        },
    },
    //판매관리
    {
        path: '/sell/list', 
        name: 'selllist',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/sell/list.vue'),
        meta: {
            authRequired: true,
        },
    },
     //판매관리 수정
     {
        path: '/sell/detail', 
        name: 'selldetail',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/sell/detail.vue'),
        meta: {
            authRequired: true,
        },
    },
    //장비로그인 기록
    {
        path: '/login/history', 
        name: 'loginhistory',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/login/history.vue'),
        meta: {
            authRequired: true,
        },
    },
    //환전신청내역
    {
        path: '/exchange/list', 
        name: 'exchangelist',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/exchange/list.vue'),
        meta: {
            authRequired: true,
        },
    },
    //환전신청내역 수정
    {
        path: '/exchange/detail', 
        name: 'exchangedetail',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/exchange/detail.vue'),
        meta: {
            authRequired: true,
        },
    },
    //faq관리
    {
        path: '/faq/list', 
        name: 'faqlist',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/faq/list.vue'),
        meta: {
            authRequired: true,
        },
    },
    //faq 수정
    {
        path: '/faq/detail', 
        name: 'faqdetail',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/faq/detail.vue'),
        meta: {
            authRequired: true,
        },
    },
    //공지사항관리
    {
        path: '/notice/list', 
        name: 'noticelist',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/notice/list.vue'),
        meta: {
            authRequired: true,
        },
    },
    //공지사항 수정
    {
        path: '/notice/detail', 
        name: 'noticedetail',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/notice/detail.vue'),
        meta: {
            authRequired: true,
        },
    },
    //AI 데이터 확인
     {
        path: '/detect/detail', 
        name: 'detectdetail',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/detect/list.vue'),
        meta: {
            authRequired: true,
        },
        
    },
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {

    var token = VueCookies.get('reoilcms_accessToken');
    var isVerified = false;

    //return next();

    if(token !== null){
        let decoded = VueJwtDecode.decode(token);
        //console.log(decoded);
        var expTime = decoded.exp;
        var expireDate = new Date(expTime * 1000);
        var today = new Date();
        var UserUqid = decoded.UserUqid;
        
        // accessToken 유효한지 검사
        if(expireDate > today){
            isVerified = true;
            
        }
    }
    //store.commit('logoutUser');

    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }

    if(to.path.includes("auth")){
        return next();
    }else{
        if (store.state.user.loggedIn){
            return next();
        }else{
            if (token !== null){
                //accessToken이 있을 경우 진행
                var userInfo = {
                    "accessToken": VueCookies.get('reoilcms_accessToken'),
                    "refreshToken": VueCookies.get('reoilcms_refreshToken'),
                    "userUqId": UserUqid,
                    "userId": VueCookies.get('reoilcms_userId'),
                };

                //console.log(userInfo);
        
                if(isVerified){

                    // accessToken이 유효할 경우
                    store.commit('setLoggedIn', true);
                    store.commit('resetUserInfo', userInfo);

                    return next();

                }else{

                    // accessToken이 만료되었을 경우 - refreshToken으로 재발급
                    store.dispatch('adminStore/refresh_token', userInfo)
                    .then((res) => {
                        //console.log(res);
                        if(res.success){
                            let decoded = VueJwtDecode.decode(res.accessToken);
                            //console.log(decoded);
        
                            var userInfo_update = {
                                "accessToken": res.accessToken,
                                "refreshToken": res.refreshToken,
                                "userUqId": decoded.UserUqid,
                                "userId": VueCookies.get('reoilcms_userId'),
                            };
        
                            //console.log(userInfo);
        
                            store.commit('setLoggedIn', true);
                            store.commit('resetUserInfo', userInfo_update);
                            
                            return next();

                        }else{
                            //alert("다른 기기에서 로그인하였습니다. 다시 로그인 해주세요.");
                            next("/auth/login");
                        }
                        return res;
                    })
                    .catch(({message}) => (alert(message)));
                }
                
            }else{
                if(!isVerified && token !== null){
                    // accessToken이 종료되었을 경우 로그아웃 진행
                    store.commit('logoutUser')

                    var userInfo = {
                        "userUqId": UserUqid,
                    };

                    store.dispatch('usersStore/logout', userInfo)
                    .then((res) => {
                        //console.log(res);
                        if(res.success){
                        
                            store.commit('logoutUser');

                        }
                        return res;
                    })
                    .catch(({message}) => (alert(message)));
                }
                const authRequired = to.matched.some((route) => route.meta.authRequired)

                if(!authRequired){
                    return next();
                }else{
                    //alert('로그인이 필요한 페이지입니다!');
                    next("/auth/login");
                }
            }
        }
    }
});

export default router;
